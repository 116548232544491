import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import { trackPendoEvent } from 'services/pendo'

import { Button, ButtonType, Stepper } from '@junglescout/edna'
import PpcSyncCongratulations from 'ui_elements/PpcSyncCongratulations/PpcSyncCongratulations'
import { Footer } from 'ui_elements/TutorialModal/TutorialModal'

import { MWS_SYNC_COMPLETED } from 'constants/activation_points/mws'
import { MWS_PPC_SYNC_STEPS } from 'constants/account'
import { MWS_SYNC_DATA_ATTRIBUTE_IDS } from 'constants/data_attributes/account/mwsSync'

import { AbstractModal } from './Abstract'

class CongratulationsStep extends AbstractModal {
  componentDidMount() {
    trackPendoEvent(MWS_SYNC_COMPLETED)
  }

  renderSteps() {
    return (
      <Stepper steps={MWS_PPC_SYNC_STEPS} activeIndex={this.activeStepIndex} />
    )
  }

  renderContent() {
    return (
      <PpcSyncCongratulations
        ppcSyncWasSkipped={this.props.ppcSyncWasSkipped}
      />
    )
  }

  renderFooter() {
    const { t, fromSetupPage } = this.props

    // Don't render next button in setup wizard. Next step is handled by the wizard itself.
    if (fromSetupPage) {
      return null
    }

    const buttonLabel = fromSetupPage
      ? t('generic:Next', 'Next')
      : t('generic:Finish', 'Finish')

    const dataId = fromSetupPage
      ? MWS_SYNC_DATA_ATTRIBUTE_IDS.Buttons.MwsModal.SetupWizardFinish
      : MWS_SYNC_DATA_ATTRIBUTE_IDS.Buttons.MwsModal.Finish

    return (
      <Footer>
        <Button
          btnType={ButtonType.PRIMARY}
          onClick={() => this.onNextClick()}
          isLoading={this.nextButtonLoading}
          dataId={dataId}
          disabled={this.nextButtonDisabled}>
          {buttonLabel}
        </Button>
      </Footer>
    )
  }

  onNextClick = () => {
    const { showMwsSyncModal, isUpgrade } = this.props

    showMwsSyncModal(this.nextModal, isUpgrade)
  }

  get nextModal() {
    return null
  }

  get nextButtonDisabled() {
    return false
  }

  get backButtonDisabled() {
    return true
  }

  get activeStepIndex() {
    return 4
  }
}

CongratulationsStep.propTypes = {
  previousModal: PropTypes.string,
  showMwsSyncModal: PropTypes.func,
  ppcSyncWasSkipped: PropTypes.bool,
  fromSetupPage: PropTypes.bool,
  flagData: PropTypes.object
}

export default withTranslation(['mwsSync', 'generic'])(CongratulationsStep)

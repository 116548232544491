import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { withTranslation, Trans } from 'react-i18next'

import { TEXT_STYLES } from 'TEXT_STYLES'

import { Button, ButtonType } from '@junglescout/edna'
import { MwsSyncSteps } from 'ui_elements/MwsSyncSteps/MwsSyncSteps'
import { QuestionIcon } from 'icons/QuestionIcon/QuestionIcon'

import { KIT_CODE } from 'constants/admin/shared'
import { AMAZON_MWS_PPC_SYNC_STEPS } from 'constants/account'
import { MWS_SYNC_DATA_ATTRIBUTE_IDS } from 'constants/data_attributes/account/mwsSync'

import { AbstractModal, Footer } from './Abstract'

const SubHeader = styled.h3`
  ${TEXT_STYLES.H3Black}
  margin-top: 5px;
  margin-bottom: 5px;
`
const P = styled.p`
  ${TEXT_STYLES.BodyBlack}
  margin: 0;
`

const StepsWrapper = styled.div`
  display: flex;
  justify-content: center;
`

const IntroductionFooter = styled(Footer)`
  display: flex;
  justify-content: flex-end;
`

const BillingNote = styled.div`
  background: ${props => props.theme.colors.grey100};
  display: flex;
  width: 100%;
  align-items: center;
  margin-right: 21px;
  padding-left: 5px;
`

const BillingLink = styled.a`
  color: ${props => props.theme.colors.primary};
  cursor: pointer;
`

const QuestionIconWrapper = styled.div`
  padding: 4px;
  margin-right: 2px;
  height: 100%;
  display: flex;
  align-items: center;
`

class IntroductionStep extends AbstractModal {
  renderContent() {
    const { t } = this.props

    const subHeaderTitle = t(
      'mwsSync:IntroductionModal.subHeaderTitle',
      'Sync your Amazon & Jungle Scout accounts to get started.'
    )
    const subHeaderMessage = t(
      'mwsSync:IntroductionModal.subHeaderMessage',
      'Become a better seller with access to our full set of features. Available to those with an Amazon Seller Central account.'
    )

    return (
      <>
        <SubHeader>{subHeaderTitle}</SubHeader>
        <P>{subHeaderMessage}</P>
        <StepsWrapper>
          <MwsSyncSteps
            width="190px"
            height="140px"
            seperatorOffset="260px"
            stepImageMargin="60px 0 30px 0"
          />
        </StepsWrapper>
      </>
    )
  }

  renderFooter() {
    const nextStepButtonLabel = this.props.t(
      'mwsSync:IntroductionModal.nextStepButtonLabel',
      'Get Started!'
    )
    // override footer - it's unique to this modal
    // hide not a seller button https://github.com/Junglescout/junglescout_client/issues/1615
    return (
      <IntroductionFooter float="right">
        {this.props.membershipCode === KIT_CODE && (
          <BillingNote>
            <QuestionIconWrapper>
              <QuestionIcon />
            </QuestionIconWrapper>
            <span>
              <Trans i18nKey="mwsSync:IntroductionModal.billingNote">
                Your billing details may change based on your monthly order
                count,{' '}
                <BillingLink
                  data-id={
                    MWS_SYNC_DATA_ATTRIBUTE_IDS.Buttons.MwsModal
                      .ClickHerePricing
                  }>
                  click here
                </BillingLink>{' '}
                for more details.
              </Trans>
            </span>
          </BillingNote>
        )}
        <Button
          btnType={ButtonType.PRIMARY}
          onClick={() => this.onNextClick()}
          width="115px"
          dataId={MWS_SYNC_DATA_ATTRIBUTE_IDS.Buttons.MwsModal.GetStarted}>
          {nextStepButtonLabel}
        </Button>
      </IntroductionFooter>
    )
  }

  renderSteps() {
    // override method - there's no steps on the first modal
    return null
  }

  get nextModal() {
    return AMAZON_MWS_PPC_SYNC_STEPS.selectRegion
  }

  get width() {
    return '770px'
  }
}

IntroductionStep.propTypes = {
  modalHeaderType: PropTypes.string,
  membershipCode: PropTypes.string
}

export default withTranslation(['mwsSync'])(IntroductionStep)

import React from 'react'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import { TEXT_STYLES } from 'TEXT_STYLES'
import { COLORS } from 'COLORS'
import { MultiSelect } from 'ui_elements/MultiSelect/MultiSelect'
import { PrivacyExpandable } from 'ui_elements/PrivacyExpandable/PrivacyExpandable'
import { FieldGroup } from 'ui_elements/FieldGroup/FieldGroup'

import {
  AMAZON_MWS_PPC_SYNC_STEPS,
  MWS_MARKETPLACE_DATA_IDS
} from 'constants/account'
import { MWS_SYNC_DATA_ATTRIBUTE_IDS } from 'constants/data_attributes/account/mwsSync'

import { FlagIcon } from '@junglescout/edna'
import { AbstractModal } from './Abstract'

const Message = styled.div`
  margin: 20px 0;
  width: 100%;
`
const P = styled.p`
  ${TEXT_STYLES.BodyBlack}
  margin: 0;
  margin-bottom: 18px;
`
const CustomSelect = styled(MultiSelect)`
  width: 100%;
  ${TEXT_STYLES.BodyBlack}

  .selected {
    .display-name {
      color: ${COLORS.primary} !important;
    }
  }

  & > div > ul > li > div {
    /* fix the padding on the dropdown items */
    margin-left: 0px;
  }

  & > div:last-child {
    z-index: 100000;
  }

  ul {
    margin-top: 10px;
  }
`
const MarketplaceTitle = styled.span`
  display: flex;
  align-items: center;
  gap: 8px;
`
const Inputs = styled.div`
  padding-bottom: 36px;
`

class SelectMarketplaceStep extends AbstractModal {
  state = {
    regionSelectOpen: false,
    marketplaceSelectOpen: false
  }

  componentDidMount() {
    if (
      !Array.isArray(this.props.availableMarketplaces.eu) ||
      this.props.availableMarketplaces.eu.length < 1
    ) {
      this.props.getAmazonMarketplacesList()
    }
  }

  onRegionSelection(event) {
    this.props.mwsSyncSetRegion(event.value)
    this.setState({
      marketplaceSelectDisabled: false,
      regionSelectOpen: false
    })
  }

  onRegionSelectToggle() {
    const { regionSelectOpen } = this.state
    this.setState({ regionSelectOpen: !regionSelectOpen })
  }

  onMarketplaceSelection(event) {
    this.props.mwsSyncSetMarketplace(event.value)
    this.setState({
      marketplaceSelectOpen: false
    })
  }

  onMarketplaceToggle() {
    const { marketplaceSelectOpen } = this.state
    this.setState({ marketplaceSelectOpen: !marketplaceSelectOpen })
  }

  renderRegionSelect() {
    const {
      t,
      data: { region }
    } = this.props

    const defaultTitle = t(
      'mwsSync:SelectMarketplaceModal.regionSelectorTitle',
      'Select Region'
    )

    const values = [
      {
        name: 'North America',
        value: 'na',
        pendoId: MWS_SYNC_DATA_ATTRIBUTE_IDS.Dropdowns.MwsModal.MwsRegionNA
      },
      {
        name: 'Europe',
        value: 'eu',
        pendoId: MWS_SYNC_DATA_ATTRIBUTE_IDS.Dropdowns.MwsModal.MwsRegionEU
      },
      {
        name: 'Far East',
        value: 'fe',
        pendoId: MWS_SYNC_DATA_ATTRIBUTE_IDS.Dropdowns.MwsModal.MwsRegionFE
      }
    ]

    let title = defaultTitle
    const marketplaceTitle = values?.find(item => item.value === region)?.name

    if (region && marketplaceTitle) {
      title = marketplaceTitle
    }

    const regionSelectLabel = t(
      'mwsSync:SelectMarketplaceModal.regionSelectLabel',
      'Region'
    )

    return (
      <FieldGroup>
        <label>{regionSelectLabel}</label>
        <CustomSelect
          title={title}
          elements={values}
          selectedElements={[region]}
          isComponentOpen={this.state.regionSelectOpen}
          handleSelection={event => this.onRegionSelection(event)}
          handleOutsideClick={() => this.onRegionSelectToggle()}
          handleToggle={() => this.onRegionSelectToggle()}
          disableSelectAll
          forceSingleSelection
          hideSelector
          width="100%"
        />
      </FieldGroup>
    )
  }

  applyPendoTaggingToMarketplaces(region) {
    const availableMarketplaces = this.props.availableMarketplaces[region]
    if (!Array.isArray(availableMarketplaces)) return []
    availableMarketplaces.forEach(marketplace => {
      const itemMarketplace = marketplace
      const { countryCode = '' } = itemMarketplace
      if (countryCode in MWS_MARKETPLACE_DATA_IDS) {
        itemMarketplace.pendoId = MWS_MARKETPLACE_DATA_IDS[countryCode]
      }
    })
    return availableMarketplaces
  }

  renderMarketplaceSelect() {
    const {
      t,
      data: { region, primary_marketplace_id }
    } = this.props

    const defaultTitle = t(
      'mwsSync:SelectMarketplaceModal.marketplaceSelectorTitle',
      'Select Primary Marketplace'
    )

    let values = []
    let title = defaultTitle

    if (region) {
      const availableMarketplaces = this.applyPendoTaggingToMarketplaces(region)

      if (Array.isArray(availableMarketplaces)) {
        if (availableMarketplaces.length > 0) {
          values = availableMarketplaces.map(marketplace => ({
            name: (
              <MarketplaceTitle>
                <FlagIcon country={marketplace.countryCode} size="15px" />
                {marketplace.name}
              </MarketplaceTitle>
            ),
            value: marketplace.value,
            countryName: marketplace.name
          }))
        } else {
          title = t(
            'mwsSync:SelectMarketplaceModal.marketplaceSelectorTitleLoading',
            'Loading marketplaces...'
          )
        }
      }
    }

    const marketplace = primary_marketplace_id

    if (marketplace) {
      const value = values.find(item => item.value === marketplace)
      title = value ? value.name : title
    }

    const marketplaceSelectLabel = t(
      'mwsSync:SelectMarketplaceModal.marketplaceSelectLabel',
      'Primary Marketplace'
    )

    return (
      <FieldGroup>
        <label>{marketplaceSelectLabel}</label>
        <CustomSelect
          title={title}
          elements={values}
          selectedElements={[marketplace]}
          isComponentOpen={this.state.marketplaceSelectOpen}
          handleSelection={event => this.onMarketplaceSelection(event)}
          handleOutsideClick={() => this.onMarketplaceToggle()}
          handleToggle={() => this.onMarketplaceToggle()}
          disableSelectAll
          forceSingleSelection
          hideSelector
          disabled={!region || values.length < 1}
          width="100%"
        />
      </FieldGroup>
    )
  }

  renderContent() {
    const { t, fromSetupPage } = this.props
    const message = t(
      'mwsSync:SelectMarketplaceModal.message',
      'Select your Amazon Seller Central region and primary marketplace. We will also add all of your Amazon accounts for that region.'
    )

    const secondaryMessage = t(
      'mwsSync:SelectMarketplaceModal.secondaryMessage',
      'Operate in multiple marketplaces? Don’t worry, you will be able to add them through your User Settings page later.'
    )
    return (
      <div>
        <Message>
          <P>{message}</P>
          <P>{secondaryMessage}</P>
          {!fromSetupPage && (
            <PrivacyExpandable
              title={t(
                'setup_wizard:MwsSyncPage.privacyTitle',
                'Is my data private and secure?'
              )}
              dataId={MWS_SYNC_DATA_ATTRIBUTE_IDS.Buttons.MwsModal.PrivacyNote}
            />
          )}
        </Message>

        <Inputs>
          {this.renderRegionSelect()}
          {this.renderMarketplaceSelect()}
        </Inputs>
      </div>
    )
  }

  // eslint-disable-next-line class-methods-use-this
  get nextModal() {
    return AMAZON_MWS_PPC_SYNC_STEPS.accountSync
  }

  get previousModal() {
    return this.props.hideBackButton
      ? null
      : AMAZON_MWS_PPC_SYNC_STEPS.introduction
  }

  get nextButtonDisabled() {
    return !this.props.data.region || !this.props.data.primary_marketplace_id
  }

  // eslint-disable-next-line class-methods-use-this
  get activeStepIndex() {
    return 0
  }
}

SelectMarketplaceStep.defaultProps = {
  hideBackButton: false
}

SelectMarketplaceStep.propTypes = {
  previousModal: PropTypes.string,
  showMwsSyncModal: PropTypes.func,
  data: PropTypes.objectOf(PropTypes.string),
  mwsSyncSetRegion: PropTypes.func,
  mwsSyncSetMarketplace: PropTypes.func,
  getAmazonMarketplacesList: PropTypes.func,
  availableMarketplaces: PropTypes.objectOf(PropTypes.array),
  hideBackButton: PropTypes.bool,
  fromSetupPage: PropTypes.bool
}

export default withTranslation(['mwsSync', 'setup_wizard'])(
  SelectMarketplaceStep
)

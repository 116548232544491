import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import { EmptyState, Text } from '@junglescout/edna'

import { JSPRO_BUCKET } from 'constants/s3'

export const PpcSyncSuccess = ({ padding }) => {
  const { t } = useTranslation('mwsSync')

  return (
    <EmptyState
      asCard={false}
      image={`${JSPRO_BUCKET}/images/ppc-permissions.png`}
      header={t(
        'mwsSync:PpcSyncStep.successPage.header',
        'Connecting...One last step!'
      )}
      message={
        <>
          <Text variant="bodyLg" align="center" color="grey600">
            {t(
              'mwsSync:PpcSyncStep.successPage.message1',
              'Your PPC account is syncing, and data may take up to 24 hours to load.'
            )}
          </Text>
          <br />
          <br />
          <Trans i18nKey="mwsSync:PpcSyncStep.successPage.message2">
            One last step: ensure your Ad Console Campaign Manager permissions
            are set to "View" or higher to keep your data accurate. Check out
            this{' '}
            <a
              href="https://support.junglescout.com/hc/en-us/articles/360036000974-Connect-to-Amazon-Enabling-Access-to-your-PPC-Account"
              target="_blank"
              rel="noopener noreferrer">
              help doc
            </a>{' '}
            for guidance.
          </Trans>
        </>
      }
      padding={padding}
    />
  )
}

PpcSyncSuccess.defaultProps = {
  padding: '24px 0 0 0'
}

PpcSyncSuccess.propTypes = {
  padding: PropTypes.string
}

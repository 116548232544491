import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { withTranslation, Trans } from 'react-i18next'

import { TEXT_STYLES } from 'TEXT_STYLES'

import { Button, ButtonType } from '@junglescout/edna'
import { PpcSyncLwa } from 'ui_elements/PpcSyncLwa/PpcSyncLwa'

import { AMAZON_MWS_PPC_SYNC_STEPS, PPC_SYNC_STATUS } from 'constants/account'

import { AbstractModal, Footer } from './Abstract'

const P = styled.p`
  ${TEXT_STYLES.H4Black}
  margin: 0;
  margin-bottom: 20px;
`
const InitialWrapper = styled.div`
  margin-top: 20px;
  min-height: 340px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
  padding: 15px;
`

const RetryWrapper = styled.div`
  margin-top: 30px;
`
const RetryMessageWrapper = styled.div`
  ${TEXT_STYLES.BodyBlack}
  margin-top: 20px;
  b {
    font-weight: 500;
  }
`

class PpcSyncStep extends AbstractModal {
  renderContent() {
    const { ppcSyncStatus, t } = this.props

    const header = t('mwsSync:PpcSyncStep.header', 'Sync PPC data')

    const ppcSync = (
      <PpcSyncLwa
        btnType={ButtonType.SECONDARY}
        ppcSyncStatus={ppcSyncStatus}
        sellerAccountId={this.props.sellerAccountId}
        withMwsSync
      />
    )

    switch (ppcSyncStatus) {
      case PPC_SYNC_STATUS.initial:
        return (
          <InitialWrapper>
            <P>{header}</P>
            {ppcSync}
          </InitialWrapper>
        )
      case PPC_SYNC_STATUS.success:
        return <div>{ppcSync}</div>
      case PPC_SYNC_STATUS.failure:
        return (
          <RetryWrapper>
            {ppcSync}
            <RetryMessageWrapper>
              <Trans i18nKey="mwsSync:PpcSyncStep.header">
                Click <b>“Skip &amp; Next”</b> to skip PPC data sync. You can go
                to Settings when ready to enable your PPC data.
              </Trans>
            </RetryMessageWrapper>
          </RetryWrapper>
        )
      default:
        return null
    }
  }

  renderFooter() {
    const { ppcSyncStatus, t } = this.props

    const nextButtonLabel =
      ppcSyncStatus === PPC_SYNC_STATUS.success
        ? t('generic:Next', 'Next')
        : t('generic:SkipAndNext', 'Skip & Next')

    return (
      <Footer>
        <Button
          btnType={ButtonType.PRIMARY}
          onClick={() => this.onNextClick()}
          isLoading={this.nextButtonLoading}
          disabled={this.nextButtonDisabled}>
          {nextButtonLabel}
        </Button>
      </Footer>
    )
  }

  onNextClick() {
    const {
      ppcSyncStatus,
      setPpcSyncStepSkipped,
      showMwsSyncModal,
      isUpgrade
    } = this.props

    setPpcSyncStepSkipped(ppcSyncStatus !== PPC_SYNC_STATUS.success)
    showMwsSyncModal(this.nextModal, isUpgrade)
  }

  get nextModal() {
    return AMAZON_MWS_PPC_SYNC_STEPS.accountSetup
  }

  get nextButtonDisabled() {
    return false
  }

  get backButtonDisabled() {
    return true
  }

  get activeStepIndex() {
    return 2
  }
}

PpcSyncStep.propTypes = {
  setPpcSyncStepSkipped: PropTypes.func,
  ppcSyncStatus: PropTypes.string
}

export default withTranslation(['mwsSync'])(PpcSyncStep)
